import React from "react"
import { GoogleMap, Polygon, OverlayView } from '@react-google-maps/api'
import wktParser from 'wkt'
import Color from "colorjs.io"
import { Card, Container, Row, Col } from "react-bootstrap"

export default class JobMapCard extends React.Component {

  constructor(props) {
    super(props);

    // info from props
    this.feedlot_id = props.feedlot_id
    this.job_id = props.job_id
    this.is_admin = props.is_admin || false
    this.job_finished = props.job_finished || false

    // in use colors
    this.negativeColor = "#ff0000"
    this.negativeColorLight = "#ffb4b4"
    this.positiveColor = "#0000ff"
    this.positiveColorLight = "#b4b4ff"
    this.matchColor = "#00ff00"
    this.missingColor = "#e08500"
    
    // max difference of cattle to be displayed
    this.differenceMax = 10

    // check for mobile use and modify views via this variable
    this.isMobile = detectMob()
    
    // state of class, changing these will re render the view
    this.state = {
      job_finished: props.job_finished || false,

      refpens: null,
      pens: null,
      flightPlans: [],
      
      selection: "difference", // difference, alleys, gates, missing, overview(only for clients)
      selectedFlightPlanIds: [],
      zoom: null // tracked to update labels
    }

    this.mapContainerStyle = {
      width: "100%",
      height: "100%"
    }

    this.googleMap // stored map variable
    this.popupWindow // stored popupwindow - only used for mobile views
    this.viewChanged = false // temp variable to check if map panned or zoomed // the zoom state will be set once map is idle  
    
    this.cattleInAlleyBadge // reference to cattle in alley badge
    this.gatesOpenBadge // reference to gates Open badge
    this.missingPensBadge // reference to missing pens badge

    // function bindings
    this.setupPolygon = this.setupPolygon.bind(this)
    this.toList = this.toList.bind(this)

    this.listener = this.subscribe()
  }

  componentDidMount () {}

  componentWillUnmount() {
    this.listener?.unsubscribe()
  }

  render () {
    try {
      var refpens = this.state.refpens

      // setup map options and basic controls
      var mapOptions = {}
      mapOptions.disableDefaultUI = true
      mapOptions.fullscreenControl = true
      mapOptions.gestureHandling = "greedy"
      mapOptions.mapTypeId = "satellite"
      if (this.isMobile == false) {
        mapOptions.zoomControl = true
        mapOptions.mapTypeControl = true
      }

      const is_admin = this.is_admin

      return (

        <Card style={{height: "70vh"}}>
          <Card.Header>
            <Container fluid className="p-0">
            <Row className="row-gap-2 noflexwrap justify-content-between justify-content-md-start">
                <Col xs="auto" className="p-0">
                  <Card.Title>
                    Pens
                  </Card.Title>
                </Col>
                <Col xs="auto" className="p-0 ml-2">
                  <div className="btn-group d-block nowrap">
                    <button className="btn btn-primary p-0 px-2 unselected"
                      type="button" style={{fontSize: "1em"}}
                      data-tip data-for="to-list"
                      onClick={this.toList}>
                      <i className="fas fa-list"/>
                    </button>
                    <button className="btn btn-primary p-0 px-2 selected"
                      type="button" style={{fontSize: "1em"}}
                      data-tip data-for="to-map">
                      <i className="fas fa-map"/>
                    </button>
                  </div>
                  <ReactTooltip id='to-list' effect='solid' 
                       

                      textColor="black">
                      <span>Pen List</span>
                    </ReactTooltip>
                  <ReactTooltip id='to-map' effect='solid' 
                     

                    textColor="black">
                    <span>Pen Map</span>
                  </ReactTooltip>
                </Col>
              </Row>
            </Container>
          </Card.Header>
          <Card.Body style={{display: "flex", flexFlow: "column", overflow: "hidden"}}>
            {(refpens == null || refpens.length == 0) &&
              <div className="mt-2 text-center">
                <b>{refpens == null ? "Initializing" : "No Pens Found"}</b>
              </div>
            }
            {(refpens != null && refpens.length > 0) &&
              <GoogleMap
                onLoad={(map) => { 
                  this.googleMap = map
                  this.centerMapControl()
                  this.setSelectionControl()
                  this.changeSelection()
                  this.modifyBadges()
                  if(is_admin) {
                    this.setFlightPlanSelectionView()
                  }
                  
                  this.googleMap.fitBounds(this.getFeedlotBounds())

                  var _this = this
                  
                  this.googleMap.addListener('zoom_changed', () => {
                    this.viewChanged = true
                  });

                  this.googleMap.addListener('center_changed', () => {
                    this.viewChanged = true
                  });

                  this.googleMap.addListener('idle', () => {
                    var zoomLevel = _this.googleMap.getZoom();
                    if (_this.viewChanged) {
                      _this.viewChanged = false
                      _this.setState({zoom: zoomLevel})
                    }
                  })
                }}
                mapContainerStyle={this.mapContainerStyle}
                options={mapOptions}
              >
              
              {this.googleMap != null && refpens.map((refpen, i) => { // setup polygons
                return <Polygon
                  key={refpen.id}
                  paths={this.wktToPath(refpen.boundary)}
                  options={this.generatePolygonOptions(refpen)}
                  onLoad={(polygon) => {
                    this.setupPolygon(polygon, refpen)
                  }}
                  onClick={(event) => {
                    if (this.isMobile == false) { // desktop opens pen page
                      this.openRefPen(refpen)
                    } else { // open info window on mobile
                      // clear previous window
                      var infoWindow = new google.maps.InfoWindow();
                      if (this.popupWindow != null) {
                        this.popupWindow.close()
                        this.popupWindow = null
                      }

                      // create info window and display
                      infoWindow.setContent(this.createPolygonWindowContentForRefpen(refpen));
                      var latLng = this.boundaryToTopCenterCoordinate(this.getWktBoundaries(refpen.boundary))
                      infoWindow.setPosition(latLng);
                      infoWindow.open({
                        map: this.googleMap,
                        shouldFocus: false
                      });
                      this.popupWindow = infoWindow
                    }
                  }}
                />
              })}

              {this.googleMap != null && refpens.filter((refpen,i)=> { // setup labels
                  // dont check if google maps has not initialized yet
                  if (this.googleMap == null) { 
                    return false
                  }

                  // don't display null names
                  if (refpen.name == null) {
                    return false
                  }

                  
                  // calculate boundaries
                  var frame = this.googleMap.getBounds()
                  var literalBounds = this.getWktBoundaries(refpen.boundary)
                  var bounds = new google.maps.LatLngBounds({lat: literalBounds.s, lng: literalBounds.w}, {lat: literalBounds.n, lng: literalBounds.e})

                  // check if label will be in bounds
                  // remove before doing more intense processing
                  if(frame.contains(bounds.getCenter()) == false) {
                    return false
                  }
                  
                  // project to pixels
                  var proj = this.googleMap.getProjection();
                  var SW = bounds.getSouthWest();
                  var NE = bounds.getNorthEast();
                  var swPx = proj.fromLatLngToPoint(SW);
                  var nePx = proj.fromLatLngToPoint(NE);
                  var pixelWidth = (nePx.x - swPx.x)* Math.pow(2, this.state.zoom);
                  //var pixelHeight = (nePx.y - swPx.y)* Math.pow(2, this.state.zoom);

                  // if pixel width of refpen is > 50 - accept
                  if(pixelWidth > 50) {
                    return true
                  }
                  return false
                }).map((refpen, i) => {
                  
                  // get position for label
                  var bounds = this.getWktBoundaries(refpen.boundary)
                  var center = new google.maps.LatLngBounds({lat: bounds.s, lng: bounds.w}, {lat: bounds.n, lng: bounds.e}).getCenter()
                  
                  // label
                  return <OverlayView
                    key={refpen.id}
                    position={center}
                    mapPaneName={OverlayView.OVERLAY_MOUSE_TARGET}
                  >
                    <div style={{background: "#ffffffBB", borderRadius: "3px", padding: "2px", transform: "translate(-50%, -50%)"}}>
                      {refpen?.name || "Null"}
                    </div>
                  </OverlayView>
                }
              )}
              </GoogleMap>
            }
          </Card.Body>
        </Card>
      )
    } catch (error) {
      console.log(error)
      Sentry.captureException(error)
      return null
    }
  }

  subscribe() {
    var _this = this
    return App.cable.subscriptions.create(
      {
        channel: "JobDashboardChannels::JobMapCardChannel",
        feedlot_id: this.feedlot_id,
        job_id: this.job_id
      },
      {  
        connected() {
          _this.getData()
        },

        received(data) {
          if (data.refpens != null && data.pens != null && data.flightPlans != null) {
            _this.setState({
              refpens: data.refpens,
              pens: data.pens,
              flightPlans: data.flightPlans
            })
          }
        }
      }
    )
  }

  getData() {
    this.listener.perform("get_data")
  }

  toList() {
    /*postal.publish({
      channel: "PensViewToggler",
      topic: "toggle",
      data: {
        message: "List"
      }
    })*/
    if (this.is_admin) {
      window.location = Routes.admin_job_path(this.job_id)
    } else {
      window.location = Routes.job_path(this.job_id)
    }
  }

  // polygon listeners
  setupPolygon(poly, refpen) {
    var _this = this
    
     // if desktop enable hover
    if (this.isMobile == false) {
      var infoWindow = new google.maps.InfoWindow();
      
      // create info window and display
      google.maps.event.addListener(poly, 'mouseover', function (e) {
        infoWindow.setContent(_this.createPolygonWindowContentForRefpen(refpen));
        var latLng = _this.boundaryToTopCenterCoordinate(_this.getWktBoundaries(refpen.boundary))
        infoWindow.setPosition(latLng);
        infoWindow.open({
          map: _this.googleMap,
          shouldFocus: false
        });
      });
  
      // remove info window
      google.maps.event.addListener(poly, 'mouseout', function (e) {
        infoWindow.close()
      });
    }
  }

  // open pen in new tab
  openRefPen(refpen) {
    var pen = this.getPenForRefPen(refpen)
    this.openPen(pen)
  }

  // open pen in new tab
  openPen(pen) {
    console.log("open pen")
    if (pen != null) {
      postal.publish({
        channel: "PenDescriptionCard",
        topic: "pen_selection_changed",
        data: {
          pen_id: pen.id
        }
      })
    } else {
      postAlert("danger", "Pen Doesn't Exist")
    }
  }

  // create info window for refpen
  createPolygonWindowContentForRefpen(refpen) {
    var pen = this.getPenForRefPen(refpen)

    var div = document.createElement("div")
    var penNameDiv = document.createElement("div")
    penNameDiv.innerHTML = "Name: " + (refpen?.name || "")
    
    div.appendChild(penNameDiv)
    
    // if pen found - display more information
    if (pen != null) {
      if (pen.complete || this.job_finished || (this.is_admin && this.image_attached)) {
        var countDiv = document.createElement("div")
        countDiv.innerHTML = "Count: " + (pen.count || "0")

        var yardCountDiv = document.createElement("div")
        yardCountDiv.innerHTML = "Yard Count: " + (pen.yard_count || "0")

        var differenceDiv = document.createElement("div")
        differenceDiv.innerHTML = "Difference: " + ((pen.count || 0) - (pen.yard_count || 0))

        var alleyDiv = document.createElement("div")
        alleyDiv.innerHTML = "Cattle In Alley: " + pen.cattle_in_alley

        var gateDiv = document.createElement("div")
        gateDiv.innerHTML = "Gate Open: " + pen.gate_open

        div.appendChild(countDiv)
        if (pen.yard_count != null) {
          div.appendChild(yardCountDiv)
          div.appendChild(differenceDiv)
        }

        if (pen.cattle_in_alley) {
          div.appendChild(alleyDiv)
        }

        if (pen.gate_open) {
          div.appendChild(gateDiv)
        }
        
      } 
      
      // display that the pen is missing an image
      if (pen.image_attached == false) {
        var missingDiv = document.createElement("div")
        missingDiv.className = "warning-text"
        missingDiv.innerHTML = "Image Missing"
        div.appendChild(missingDiv)
      } else {
        var missingDiv = document.createElement("div")
        missingDiv.className = "success-text"
        missingDiv.innerHTML = "Image Uploaded"
        div.appendChild(missingDiv)
      }
      
      // needs reflown
      if(pen.needs_reflown) {
        var needs_reflown_div = document.createElement("div")
        needs_reflown_div.className = "text-danger"
        needs_reflown_div.innerHTML = "Needs Reflown"
        div.appendChild(needs_reflown_div)
      }

      if (this.isMobile) { // if mobile, add link to open pen
        var link = document.createElement("a")
        link.className = "btn btn-primary"
        link.href = "/pens/" + pen.id
        link.target = "_blank"
        link.innerHTML = "View"
        link.style.marginTop = "10px"
        link.style.padding = "2px 5px"
        div.appendChild(link)
      }
    }
    return div
  }

  // get maps ready path from wkt
  wktToPath(wkt) {  
    var geometry = wktParser.parse(wkt)
    
    if(geometry.type == "Polygon") {
      var points = geometry.coordinates[0].map(function(point) {
        return {lat: point[1], lng: point[0]}
      })

      // remove last point, last point is the first point repeated
      points.pop()
      return points
    }

    return []
  }

  // get boundary for wkt
  getWktBoundaries(wkt) {
    var geometry = wktParser.parse(wkt)
    var n,s,e,w
    if(geometry.type == "Polygon") {
      for (var point of geometry.coordinates[0]) {
        n = ((n || point[1]) > point[1]) ? n : point[1]
        s = ((s || point[1]) < point[1]) ? s : point[1]
        e = ((e || point[0]) > point[0]) ? e : point[0]
        w = ((w || point[0]) < point[0]) ? w : point[0]
      }
    }
    return {n: n, s: s, e: e, w: w}
  }

  // calculated top center from boundary
  boundaryToTopCenterCoordinate(boundary) {
    return {lat: boundary.n, lng: boundary.w - (boundary.w - boundary.e)/2}
  }

  // get overall bounds of entire feedlot
  getFeedlotBounds(onlySelectedFlightPlans = false) {
    var n,s,e,w
    var refpens = this.state.refpens
    for (var refpen of refpens) {

      if(onlySelectedFlightPlans && this.state.selectedFlightPlanIds.length > 0) {
        if (this.refPenInSelectedFlightPlans(refpen) == false) {
          continue
        }
      }

      var boundaries = this.getWktBoundaries(refpen.boundary)
      n = ((n || boundaries.n) > boundaries.n) ? n : boundaries.n
      s = ((s || boundaries.s) < boundaries.s) ? s : boundaries.s
      e = ((e || boundaries.e) > boundaries.e) ? e : boundaries.e
      w = ((w || boundaries.w) < boundaries.w) ? w : boundaries.w
    }
    return {north: n, south: s, east: e, west: w}
  }

  // generate custom options for polygon based on selected view mode
  generatePolygonOptions(refpen) {
    var pen = this.getPenForRefPen(refpen)
    var inFlightPlans = this.refPenInSelectedFlightPlans(refpen)
    
    if (pen == null || pen.image_attached == false || inFlightPlans == false) {
      // calculate is pen is missed
      var markAsMissed = false
      
      // if selected mode is 'missing' then determine if it needs to be displayed as missing
      // otherwise pens will be displayed as gray
      if (this.state.selection == "missing") {
        if (inFlightPlans || pen?.image_attached == false) {
          markAsMissed = true
        }
      }
      
      return {
        fillColor: markAsMissed ? this.missingColor : "gray" ,
        fillOpacity: markAsMissed ? 1 : 0.5,
        strokeColor: markAsMissed ? "black" : "gray",
        strokeOpacity: markAsMissed ? 0.5 : 1,
        strokeWeight: 1,
        clickable: true,
        draggable: false,
        editable: false,
        zIndex: 1
      }
    }

    // display difference in cattle via color interpolation
    if (this.state.selection == "difference") {
      var difference = pen.count - (pen.yard_count || 0)
      var color = this.colorFromDifference(difference)
      return {
        fillColor: color,
        fillOpacity: 1,
        strokeColor: "black",
        strokeOpacity: 0.5,
        strokeWeight: 1,
        clickable: true,
        draggable: false,
        editable: false,
        zIndex: 11
      }
    } else if (this.state.selection == "alleys") { // display pens with cattle in alley
      var color = pen.cattle_in_alley ? this.negativeColor : this.matchColor
      return {
        fillColor: color,
        fillOpacity: 1,
        strokeColor: "black",
        strokeOpacity: 0.5,
        strokeWeight: 1,
        clickable: true,
        draggable: false,
        editable: false,
        zIndex: 11
      }
    } else if (this.state.selection == "gates") { // display pens with gates open
      var color = pen.gate_open ? this.negativeColor : this.matchColor
      return {
        fillColor: color,
        fillOpacity: 1,
        strokeColor: "black",
        strokeOpacity: 0.5,
        strokeWeight: 1,
        clickable: true,
        draggable: false,
        editable: false,
        zIndex: 11
      }
    } else if(this.state.selection == "missing") {
      // missed pens are already found at top of this function
      if (pen.needs_reflown) {
        return {
          fillColor: this.negativeColor,
          fillOpacity: 1,
          strokeColor: "black",
          strokeOpacity: 0.5,
          strokeWeight: 1,
          clickable: true,
          draggable: false,
          editable: false,
          zIndex: 11
        }
      } else { // doesn't needs reflown and has picture// ok
        return {
          fillColor: this.matchColor,
          fillOpacity: 1,
          strokeColor: "black",
          strokeOpacity: 0.5,
          strokeWeight: 1,
          clickable: true,
          draggable: false,
          editable: false,
          zIndex: 11
        }
      }
    }
  }

  // find associated pen
  getPenForRefPen(refpen) {
    var pens = this.state.pens
    for(var pen of pens) {
      if (pen.ref_pen_id == refpen.id) {
        return pen
      }
    }
    return null
  }

  // find associated refpen
  getRefPenForPen(pen) {
    var refpens = this.state.refpens
    for(var refpen of refpens) {
      if(pen.ref_pen_id == refpen.id) {
        return refpen
      }
    }
    return null
  }

  // find associated flight plan
  getFlightPlansForRefPen(refpen) {
    var flightPlans = []
    for(var flightPlan of this.state.flightPlans) {
      for(var ref_pen_id of flightPlan.ref_pen_ids) {
        if(ref_pen_id == refpen.id) {
          flightPlans.push(flightPlan)
        }
      }
    }
    return flightPlans
  }

  // create and display the center map control
  centerMapControl() {
    var _this = this
    const div = document.createElement("div")
    const controlButton = document.createElement("button")
    controlButton.className = "maps-control-btn"
    controlButton.innerHTML = "<i class='fa fa-crosshairs'></i>"
    controlButton.title = "Click to recenter the map";
    controlButton.type = "button";
    // Setup the click event listeners: simply set the map to Chicago.
    controlButton.addEventListener("click",(event) => {
      _this.setState(
        this.googleMap.fitBounds(this.getFeedlotBounds())
      )
    })
    div.appendChild(controlButton)
    this.googleMap.controls[google.maps.ControlPosition.TOP_LEFT].push(div)

  }

  // create and display the selectable view types
  setSelectionControl() {
    var _this = this
    var alleyCount = this.getCattleInAlleyPens().length
    var gateCount = this.getGateOpenPens().length
    const viewControl = document.createElement("div");
    viewControl.className = "google-maps-control"

    ////////////////// difference section
    const formChild1 = document.createElement("div")
    formChild1.className = "form-check"
    
    const input1 = document.createElement("input")
    input1.type = "radio"
    if (this.state.selection == "difference") {
      input1.checked = true
    }
    input1.className = "form-check-input"
    input1.name = "selection"
    input1.id = "difference"
    input1.onclick = function(event){
      //if (_this.job_finished || _this.is_admin) {
        _this.changeSelection("difference")
      //} else {
      //  event.stopPropagation(); 
      //  event.preventDefault();
      //  alert("Unavailable Until Job Completion")
      //}
    }
    
    const label1 = document.createElement("label")
    label1.style.lineHeight = "1.5em"
    label1.className = "form-check-label"
    label1.innerHTML = "Difference"
    label1.htmlFor = "difference"
    
    formChild1.appendChild(input1)
    formChild1.appendChild(label1)

    ////////////////// Cattle in aleey section
    const formChild2 = document.createElement("div")
    formChild2.className = "form-check"
    
    const input2 = document.createElement("input")
    input2.type = "radio"
    input2.className = "form-check-input"
    input2.name = "selection"
    input2.id = "cattle"
    input2.onclick = function(event){
      //if (_this.job_finished || _this.is_admin) {
        _this.changeSelection("alleys")
      //} else {
      //  event.stopPropagation(); 
      //  event.preventDefault();
      //  alert("Unavailable Until Job Completion")
      //}
    }
    
    
    const label2 = document.createElement("label")
    label2.style.lineHeight = "1.5em"
    label2.className = "form-check-label"
    label2.innerHTML = "Cattle In Alley"
    label2.htmlFor = "cattle"

    var cattleInAlleyBadge = document.createElement("span")
    cattleInAlleyBadge.className = "badge badge-primary"
    cattleInAlleyBadge.style.marginLeft = "5px"
    cattleInAlleyBadge.innerHTML = alleyCount
    label2.appendChild(cattleInAlleyBadge)
    this.cattleInAlleyBadge = cattleInAlleyBadge
    

    formChild2.appendChild(input2)
    formChild2.appendChild(label2)

    ////////////////// Gates Open section
    const formChild3 = document.createElement("div")
    formChild3.className = "form-check"
    
    const input3 = document.createElement("input")
    input3.type = "radio"
    input3.className = "form-check-input"
    input3.name = "selection"
    input3.id = "gate"
    input3.onclick = function(event){
    //  if (_this.job_finished || _this.is_admin) {
        _this.changeSelection("gates")
    //  } else {
    //    event.stopPropagation(); 
    //    event.preventDefault();
    //    alert("Unavailable Until Job Completion")
    //  }
    }

    const label3 = document.createElement("label")
    label3.style.lineHeight = "1.5em"
    label3.className = "form-check-label"
    label3.innerHTML = "Gates Open"
    label3.htmlFor = "gate"
    
    const gatesOpenBadge = document.createElement("span")
    gatesOpenBadge.className = "badge badge-primary"
    gatesOpenBadge.style.marginLeft = "5px"
    gatesOpenBadge.innerHTML = gateCount
    label3.appendChild(gatesOpenBadge)
    this.gatesOpenBadge = gatesOpenBadge
    

    formChild3.appendChild(input3)
    formChild3.appendChild(label3)

    viewControl.appendChild(formChild1)
    viewControl.appendChild(formChild2)
    viewControl.appendChild(formChild3)

  
    if (this.is_admin) {
      var missingPens = this.getMissingPenRefIds()
      const formChild4 = document.createElement("div")
      formChild4.className = "form-check"
      
      const input4 = document.createElement("input")
      input4.type = "radio"
      if (this.state.selection == "missing") {
        input4.checked = true
      }
      input4.className = "form-check-input"
      input4.name = "selection"
      input4.id = "missing"
      input4.onclick = function(){
        _this.changeSelection("missing")
      }

      const label4 = document.createElement("label")
      label4.style.lineHeight = "1.5em"
      label4.className = "form-check-label"
      label4.innerHTML = "Missing Images"
      label4.htmlFor = "missing"
      
      const missingPensBadge = document.createElement("span")
      missingPensBadge.className = "badge badge-primary"
      missingPensBadge.style.marginLeft = "5px"
      missingPensBadge.innerHTML = missingPens.length
      label4.appendChild(missingPensBadge)
      this.missingPensBadge = missingPensBadge

      formChild4.appendChild(input4)
      formChild4.appendChild(label4)

      viewControl.appendChild(formChild4)

    }
    this.googleMap.controls[google.maps.ControlPosition.RIGHT_TOP].insertAt(0,viewControl)
  }

  // legend associated with showing differences in count vs yard_count
  setDifferenceLegend() {
    const legend = document.createElement("div");
    legend.className = "maps-legend"
    const div = document.createElement("div");

    const gradient = document.createElement("div");
    gradient.style.width = "10px"
    gradient.style.height = this.isMobile ? "100px" : "150px"
    gradient.style.background = "linear-gradient(180deg, rgba(255,0,0,1) 0%, rgba(255,180,180,1) 47%, rgba(0,255,0,1) 47%, rgba(0,255,0,1) 53%, rgba(180,180,255,1) 53%, rgba(0,0,255,1) 100%)"
    
    const labels = document.createElement("div")
    labels.style.width = this.isMobile ? "45px" : "50px"
    labels.style.height = this.isMobile ? "100px" : "150px"
    labels.style.marginLeft = this.isMobile ? "5px" : "10px"

    const label1 = document.createElement("div")
    label1.style.position = "absolute"
    label1.style.top = 0
    label1.innerHTML = "-10"

    const label2 = document.createElement("div")
    label2.style.margin = "0"
    label2.style.position = "absolute"
    label2.style.top = "50%"
    label2.style.transform = "translateY(-50%)"
    label2.innerHTML = "Match"

    const label3 = document.createElement("div")
    label3.style.position = "absolute"
    label3.style.bottom = 0
    label3.innerHTML = "10"

    labels.appendChild(label1)
    labels.appendChild(label2)
    labels.appendChild(label3)

    div.appendChild(gradient)
    legend.appendChild(div)
    legend.appendChild(labels)

    this.googleMap.controls[google.maps.ControlPosition.RIGHT_TOP].push(legend);
  }

  // legend associated with cattle in alley
  setAlleyLegend() {
    const legend = document.createElement("div");
    legend.className = "maps-legend"
    legend.style.textAlign = "left"

    const div = document.createElement("div");

    const alleyBox = document.createElement("div");
    alleyBox.className = "box"
    alleyBox.style.background = this.negativeColor
    alleyBox.style.display = "inline-block"
    alleyBox.style.marginTop = "5px"
    
    const alleyLabel = document.createElement("div")
    alleyLabel.innerHTML = "Cattle In Alley"
    alleyLabel.style.display = "inline"
    alleyLabel.style.marginLeft = "5px"
    alleyLabel.style.verticalAlign = "super"

    const clearBox = document.createElement("div");
    clearBox.className = "box"
    clearBox.style.background = this.matchColor
    clearBox.style.display = "inline-block"
    clearBox.style.marginTop = "5px"
    
    const clearLabel = document.createElement("div")
    clearLabel.innerHTML = "Clear"
    clearLabel.style.display = "inline"
    clearLabel.style.marginLeft = "5px"
    clearLabel.style.verticalAlign = "super"

    div.appendChild(alleyBox)
    div.appendChild(alleyLabel)
    div.appendChild(document.createElement("br"))
    div.appendChild(clearBox)
    div.appendChild(clearLabel)
    
    legend.appendChild(div)

    this.googleMap.controls[google.maps.ControlPosition.RIGHT_TOP].push(legend);
  }

  // legend associated with gates open
  setGatesLegend() {
    const legend = document.createElement("div");
    legend.className = "maps-legend"
    legend.style.textAlign = "left"

    const div = document.createElement("div");

    const gateBox = document.createElement("div");
    gateBox.className = "box"
    gateBox.style.background = this.negativeColor
    gateBox.style.display = "inline-block"
    gateBox.style.marginTop = "5px"
    
    const gateLabel = document.createElement("div")
    gateLabel.innerHTML = "Gate Open"
    gateLabel.style.display = "inline"
    gateLabel.style.marginLeft = "5px"
    gateLabel.style.verticalAlign = "super"

    const clearBox = document.createElement("div");
    clearBox.className = "box"
    clearBox.style.background = this.matchColor
    clearBox.style.display = "inline-block"
    clearBox.style.marginTop = "5px"
    
    const clearLabel = document.createElement("div")
    clearLabel.innerHTML = "Clear"
    clearLabel.style.display = "inline"
    clearLabel.style.marginLeft = "5px"
    clearLabel.style.verticalAlign = "super"

    div.appendChild(gateBox)
    div.appendChild(gateLabel)
    div.appendChild(document.createElement("br"))
    div.appendChild(clearBox)
    div.appendChild(clearLabel)
    
    legend.appendChild(div)

    this.googleMap.controls[google.maps.ControlPosition.RIGHT_TOP].push(legend);
  }

  // legend associated with pens that have no image
  setMissingPensLegend() {
    const legend = document.createElement("div");
    legend.className = "maps-legend"
    legend.style.textAlign = "left"

    const div = document.createElement("div");

    const missingBox = document.createElement("div");
    missingBox.className = "box"
    missingBox.style.background = this.missingColor
    missingBox.style.display = "inline-block"
    missingBox.style.marginTop = "5px"
    
    const missingLabel = document.createElement("div")
    missingLabel.innerHTML = "Image Missing"
    missingLabel.style.display = "inline"
    missingLabel.style.marginLeft = "5px"
    missingLabel.style.verticalAlign = "super"

    const imageUploadedBox = document.createElement("div");
    imageUploadedBox.className = "box"
    imageUploadedBox.style.background = this.matchColor
    imageUploadedBox.style.display = "inline-block"
    imageUploadedBox.style.marginTop = "5px"
    
    const imageUploadedLabel = document.createElement("div")
    imageUploadedLabel.innerHTML = "Image Uploaded"
    imageUploadedLabel.style.display = "inline"
    imageUploadedLabel.style.marginLeft = "5px"
    imageUploadedLabel.style.verticalAlign = "super"

    const needsReflownBox = document.createElement("div");
    needsReflownBox.className = "box"
    needsReflownBox.style.background = this.negativeColor
    needsReflownBox.style.display = "inline-block"
    needsReflownBox.style.marginTop = "5px"
    
    const needsReflownLabel = document.createElement("div")
    needsReflownLabel.innerHTML = "Needs Reflown"
    needsReflownLabel.style.display = "inline"
    needsReflownLabel.style.marginLeft = "5px"
    needsReflownLabel.style.verticalAlign = "super"

    div.appendChild(missingBox)
    div.appendChild(missingLabel)
    div.appendChild(document.createElement("br"))
    div.appendChild(imageUploadedBox)
    div.appendChild(imageUploadedLabel)
    div.appendChild(document.createElement("br"))
    div.appendChild(needsReflownBox)
    div.appendChild(needsReflownLabel)
    
    legend.appendChild(div)

    this.googleMap.controls[google.maps.ControlPosition.RIGHT_TOP].push(legend);

    if (this.is_admin) {
      // display the button to create flight plan from missing pens
      this.setGeneratePlanButton()
    }
  }

  // Create button control that when pressed opens the generate flight plan page for missing pens
  // plan opens in new tab
  setGeneratePlanButton() {
    var missingPens = this.getMissingPenRefIds()

    // position in google map - determined by mobile vs desktop
    // var position = this.isMobile ? google.maps.ControlPosition.TOP_LEFT : google.maps.ControlPosition.TOP_CENTER
    var position = google.maps.ControlPosition.TOP_CENTER
    
    // attempt to remove previous button if exists
    try {
      this.googleMap.controls[position].pop()
    } catch {

    }
    
    // display only if there are missing pens
    if (missingPens.length > 0) {
      const legend = document.createElement("div");
      legend.className = "maps-legend"
      legend.style.textAlign = "center"

      const div = document.createElement("div");

      const generateButton = document.createElement("a");
      generateButton.className = "btn btn-sm"
      generateButton.style.background = "orange"
      generateButton.style.color = "white"
      generateButton.innerHTML = "Generate Flight"
      generateButton.href = Routes.generate_path_admin_job_path(this.job_id) + "?" + missingPens.map((ref_pen_id,index) => {
        return "ref_pen_ids[]=" + ref_pen_id
      }).join("&")
      generateButton.target = "_blank"
      
      div.appendChild(generateButton)

      legend.appendChild(div)

      this.googleMap.controls[position].push(legend);
    }
  }

  // create control for selecting flight plans
  // admin feature and only displays when more than 1 flight plan
  setFlightPlanSelectionView() {
    var _this = this
    var flightPlans = this.state.flightPlans
    if (flightPlans.length > 1 && this.is_admin) {
      const legend = document.createElement("div");
      legend.className = "maps-legend"
      legend.style.textAlign = "left"
      legend.style.overflowY = "scroll"
      legend.style.maxHeight = "50%"

      const div = document.createElement("div");
      div.style.height = "100%"

      for (var i in flightPlans) {
        const flightPlan = flightPlans[i]
        const btn = document.createElement("button")
        var indexLabel = parseInt(i)+1
        btn.innerHTML = "# " + indexLabel + ": Pens - " + flightPlan.ref_pen_ids.length
        btn.style.borderColor = "black"
        btn.style.borderWidth = "1px"
        btn.style.background = "white"
        btn.style.color = "black"
        btn.style.display = "block"
        btn.style.width = "100%"
        btn.style.textAlign = "left"
        btn.style.paddingTop = this.isMobile ? "5px" : "0px"
        btn.style.paddingBottom = this.isMobile ? "5px" : "0px"
        btn.addEventListener("click",(event) => {
          var selectedFlightPlanIds = this.state.selectedFlightPlanIds
          if (selectedFlightPlanIds.includes(flightPlan.id)) { // remove it
            var index = selectedFlightPlanIds.indexOf(flightPlan.id)
            selectedFlightPlanIds.splice(index,1)
            btn.style.background = "white"
            btn.style.color = "black"
          } else { // add it
            selectedFlightPlanIds.push(flightPlan.id)
            btn.style.background = getComputedStyle(document.querySelector('html')).getPropertyValue('--primary');
            btn.style.color = "white"
          }
          _this.setState(
            {selectedFlightPlanIds: selectedFlightPlanIds},
            () => {
              this.googleMap.fitBounds(this.getFeedlotBounds(true))
              this.modifyBadges()
              if (this.state.selection == "missing") {
                this.removeLegend()
                this.setMissingPensLegend()  
              }
            }
          )
        })
        div.appendChild(btn)
      }

      legend.appendChild(div)

      this.googleMap.controls[google.maps.ControlPosition.LEFT_CENTER].push(legend);
    }  
  }

  // get refpen ids for the missing pens
  // adjusted for which flight plans are selected
  getMissingPenRefIds() {
    var missingRefPenIds = []
    var refpens = this.state.refpens
    for (var refpen of refpens) {
      var pen = this.getPenForRefPen(refpen)
      if ((this.refPenInSelectedFlightPlans(refpen) && pen == null) || pen?.image_attached == false || pen?.needs_reflown == true) {
        missingRefPenIds.push(refpen.id)
      }
    }
    return missingRefPenIds
  }

  // removes the legend so that we can put up a new one
  removeLegend() {
    try {
      this.googleMap.controls[google.maps.ControlPosition.RIGHT_TOP].pop()
    } catch {

    }
  }

  // calculate color of a pen based on difference between count and yard count
  colorFromDifference(difference) {
    var maxColor
    var minColor 
    if(difference < 0) { // missing cattle
      maxColor = new Color(this.negativeColor)
      minColor = new Color(this.negativeColorLight)
    } else if (difference > 0) { // too many cattle
      maxColor = new Color(this.positiveColor)
      minColor = new Color(this.positiveColorLight)
    } else {
      return this.matchColor
    }

    var magnitude = Math.abs(difference)
    var ratio = Math.min(magnitude / this.differenceMax, 1)
    var resultColor = minColor.range(maxColor)(ratio)
    return resultColor.hex  
  }

  // when radio buttons are selected this function is called to set the state and re render map
  // also sets the appropriate legend
  changeSelection(selection = null) {
    if (selection != null) {
      this.removeLegend()
      this.setState({selection: selection})
    } else {
      selection = this.state.selection
    }

    if(selection == "difference") {
      this.setDifferenceLegend()
    } else if(selection == "alleys") {
      this.setAlleyLegend()
    } else if(selection == "gates") {
      this.setGatesLegend()
    } else if(selection == "missing") {
      this.setMissingPensLegend()
    }
  }

  // is refpen in one of the selected flight plans
  refPenInSelectedFlightPlans(refpen) {
    var flightPlans

    
    // identify which flight plans are selected
    if (this.state.selectedFlightPlanIds.length == 0) {
      // if no flight plans selected, select by available pen and flight plans
      if(this.getPenForRefPen(refpen) != null) {
        return true
      }
      flightPlans = this.state.flightPlans
    } else {
      flightPlans = this.state.flightPlans.filter((flightPlan, index) => {
        return this.state.selectedFlightPlanIds.includes(flightPlan.id) 
      })
    }

    // search for refpen id
    for(var flightPlan of flightPlans) {
      if (flightPlan.ref_pen_ids.includes(refpen.id)) {
        return true
      }
    }

    return false
  }



  // modify badges in the view mode control
  modifyBadges() {
    var missingPens = this.getMissingPenRefIds()    
    var alleyCount = this.getCattleInAlleyPens().length
    var gateCount = this.getGateOpenPens().length

    if(this.missingPensBadge != null) {
      this.missingPensBadge.innerHTML = missingPens.length
      if (missingPens.length == 0) {
        this.missingPensBadge.style.display = "none"
      } else {
        this.missingPensBadge.style.display = "inline-block"
      }
    }
    
    if(this.cattleInAlleyBadge != null) {
      this.cattleInAlleyBadge.innerHTML = alleyCount
      if (alleyCount == 0) {
        this.cattleInAlleyBadge.style.display = "none"
      } else {
        this.cattleInAlleyBadge.style.display = "inline-block"
      }
    }
    
    if(this.gatesOpenBadge != null) {
      this.gatesOpenBadge.innerHTML = gateCount
      if (gateCount == 0) {
        this.gatesOpenBadge.style.display = "none"
      } else {
        this.gatesOpenBadge.style.display = "inline-block"
      }
    }
  }

  // acounts for selected flight plans
  getCattleInAlleyPens() {
    var refpens = this.state.refpens
    var alleyPens = refpens.map((refpen) => {
      if(this.refPenInSelectedFlightPlans(refpen)) {
        var pen = this.getPenForRefPen(refpen)
        if (pen != null && pen.cattle_in_alley) {
          return pen
        }
      }
      return null
    }).filter(pen => pen != null)
    return alleyPens || []
  }

  // acounts for selected flight plans
  getGateOpenPens() {
    var refpens = this.state.refpens
    var gateOpenPens = refpens.map((refpen) => {
      if(this.refPenInSelectedFlightPlans(refpen)) {
        var pen = this.getPenForRefPen(refpen)
        if (pen != null && pen.gate_open) {
          return pen
        }
      }
      return null
    }).filter(pen => pen != null)
    return gateOpenPens || []
  }

}
