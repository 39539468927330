import React from "react"
import {Card, Container, Row, Col, Dropdown} from 'react-bootstrap' 
import PenViewComponent from '../pen_dashboard_components/PenViewComponent'

export default class PenDescriptionCard extends React.Component {

  constructor(props) {
    super(props)

    
    this.feedlot_id = props.feedlot_id
    this.job_id = props.job_id

    // optional props
    this.is_admin = props.is_admin || false
    this.show_pen = props.show_pen || false
    this.is_modal = props.is_modal || false
    this.is_mobile = props.is_mobile || false
    
    this.state = {
      pen_id: props.pen_id, // optional
      pen: null,
      heart_beats: [],
      modal_open: false,
    }

    this.getPen = this.getPen.bind(this)

    this.listener = this.subscribe()
    this.localMessageSubscriber = this.subscribeToLocalMessages()

    this.uploadImage = this.uploadImage.bind(this)
    this.editName = this.editName.bind(this)
    this.editYardCount = this.editYardCount.bind(this)
    this.duplicatePen = this.duplicatePen.bind(this)
    this.setSkipQualityControl = this.setSkipQualityControl.bind(this)
    this.setStatus = this.setStatus.bind(this)
    this.setArchive = this.setArchive.bind(this)
    this.resetPasses = this.resetPasses.bind(this)    


    if (this.is_admin == false) {
      this.currentWidth = window.innerWidth
      window.addEventListener("resize", () => {
        var previousWidth = this.currentWidth
        this.currentWidth = window.innerWidth
        if (this.currentWidth <= md_bp && previousWidth > md_bp) {
          this.forceUpdate()
        }
        if(this.currentWidth > md_bp && previousWidth <= md_bp) {
          this.forceUpdate()
        }
      });
    }
  }

  subscribeToLocalMessages() {
    var _this = this
    return postal.subscribe({
      channel: "PenDescriptionCard",
      topic: "pen_selection_changed",
      callback: function(data, envelope) {
        _this.setState({
          pen_id: data.pen_id,
          pen: null,
          heart_beats: []
        }, () => {
          _this.listener?.unsubscribe()
          $('#display-pen-modal').modal("show");
          _this.listener = _this.subscribe()
        })
      }
    });
  }

  render () {
    try {
      if (this.is_modal) {
        return (
          <div className="modal fade" id="display-pen-modal" role="dialog" tabIndex="-1">
            <div className="modal-dialog modal-dialog-centered" style={{minWidth: "90vw"}}>
              <div className="modal-content">
                <button className="close float-right mt-2" style={{position: "sticky", top: "15px", zIndex: "1", opacity: "1"}} aria-label="Close" data-dismiss="modal">
                  <span aria-hidden="true" className="p-1" style={{background: "white", borderRadius: "10px", opacity: "0.9"}}>Close</span>
                </button>
                <Container fluid className="modal-body" style={{padding: "0"}}>
                  {this.renderContent()}
                </Container>
              </div>
            </div>
          </div>
        )
      } else {
        return this.renderContent()
      }
    } catch (error) {
      console.log(error)
      Sentry.captureException(error)
      return null
    }
  }

  renderContent() {
    var pen = this.state.pen
    var pen_id = this.state.pen_id
    if(pen == null || pen_id == null) {
      return null
    }

    var is_admin = this.is_admin
    var condensed = this.is_admin == false ? this.currentWidth <= md_bp : false

    var pen_path = is_admin ? Routes.admin_pen_path(pen_id) : Routes.pen_path(pen_id)
    var job_path = is_admin ? Routes.admin_job_path(this.job_id) : Routes.job_path(this.job_id)
    var feedlot_path = is_admin ? Routes.admin_feedlot_path(this.feedlot_id) : Routes.feedlot_path(this.feedlot_id)
    //var heart_beats = this.state.heart_beats
    var status_info = {title: "", on: null, color_class: ""}
    switch (pen.status) {
      case "new":
        status_info = {title: "In Progress", on: false, color_class: ""}
        break;
      case "complete":
        status_info = {title: "Complete", on: true, color_class: "text-success"}
        break;
      default:
        status_info = {title: "Issue - Alert Developer", on: null, color_class: "text-danger"}
        break;
    }

    return (
      <Card id="pen-view-component" className="m-2">
        <Card.Header>
          <Container fluid className="p-0">
            <Row>
              <Col xs="auto" className="p-0">
                <Card.Title style={{minWidth: "max-content", display: "block"}} className="m-0">
                  <a href={pen_path} style={{color: "inherit"}}>{`Pen: ${pen.name}`}</a>
                  <div className="m-0" style={{fontSize: "0.7em"}}>
                    <a href={job_path} style={{color: "inherit"}}>{`Job: ${pen.job_name}`}</a>
                  </div>
                  <div className="m-0" style={{fontSize: "0.7em"}}>
                    <a href={feedlot_path} style={{color: "inherit"}}>{`Feedlot: ${pen.feedlot_name}`}</a>
                  </div>
                </Card.Title>
              </Col>
            </Row>
            <Row>
              <Col xs="auto" className="p-0 mt-3">
                <span className="nowrap" style={{fontSize: "1.1em"}}>
                  <span className="pr-1">
                    <b>Status:</b>
                  </span>
                  <span className={status_info.color_class}>
                    {status_info.title}
                  </span>
                  {is_admin == true && 
                    <React.Fragment>
                      {status_info.on == false &&
                        <React.Fragment>
                          <i className="btn-icon fas fa-toggle-off ml-2" 
                            data-tip data-for="set-status-tooltip"
                            onClick={() => {this.setStatus(true)}}/>
                          <ReactTooltip id='set-status-tooltip' effect='solid' 
                            textColor="black">
                            <span>Set Status To Complete</span>
                          </ReactTooltip>
                        </React.Fragment>
                      }
                      {status_info.on == true &&
                        <React.Fragment>
                          <i className="btn-icon fas fa-toggle-on text-success ml-2"
                            data-tip data-for="set-status-tooltip"
                            onClick={() => {this.setStatus(false)}}/>
                          <ReactTooltip id='set-status-tooltip' effect='solid' 
                            textColor="black">
                            <span>Set Status To In Progress</span>
                          </ReactTooltip>
                      </React.Fragment>
                      }
                    </React.Fragment>
                  }
                </span>
              </Col>
            </Row>
          </Container>
          <Dropdown>
            <Dropdown.Toggle 
              as="i" 
              className="btn-icon fas fa-ellipsis-v dropdown-no-arrow"
              bsPrefix=""
              style={{fontSize: "1.5em"}}>
            </Dropdown.Toggle>
            <Dropdown.Menu>
              {this.is_modal == true && 
                <Dropdown.Item href={pen_path} target="_blank">
                  Open Pen In New Tab
                </Dropdown.Item>
              }
              <Dropdown.Item href={Routes.original_picture_pen_path(pen_id)} target="_blank">
                Open Original Image
              </Dropdown.Item>
              <Dropdown.Item onClick={this.editName}>
                Edit Pen Name
              </Dropdown.Item>
              <Dropdown.Item onClick={this.editYardCount}>
                Edit Yard Count
              </Dropdown.Item>
              {is_admin == true &&
                <React.Fragment>
                  <hr className="text-primary"/>
                  <Dropdown.Item href={Routes.pen_locations_admin_job_path(this.job_id, {focused_pen_id: pen_id})}>
                    Pen Location
                  </Dropdown.Item>
                  <Dropdown.Item href={Routes.stats_admin_pen_path(pen_id)}>
                    History
                  </Dropdown.Item>
                  <Dropdown.Item onClick={this.duplicatePen}>
                    Duplicate Pen
                  </Dropdown.Item>
                  {/*<Dropdown.Item href={Routes.image_data_admin_pen_path(pen_id)}>
                    Image Data
                  </Dropdown.Item>*/}
                  <Dropdown.Item onClick={this.uploadImage}>
                    Upload Image
                  </Dropdown.Item>
                  <Dropdown.Item href={Routes.recount_admin_pen_path(pen_id)}>
                    Recount
                  </Dropdown.Item>
                  {pen.archived == false &&
                    <Dropdown.Item 
                    className="text-danger"
                    onClick={this.setArchive}>
                    Archive
                    </Dropdown.Item>
                  }
                </React.Fragment>
              }
            </Dropdown.Menu>
          </Dropdown>
        </Card.Header>
        <Card.Body style={{height: "auto", overflow: "hidden"}}>
          {(is_admin == false && condensed == false) && this.generateClientView()}
          {(is_admin == false && condensed == true) && this.generateClientViewCondensed()}
          {is_admin == true && this.generateAdminView()}
          <Container fluid>
            {(this.show_pen == true && this.state.pen != null) && 
              <React.Fragment>
                <Row>
                  <Col xs={12} className="mb-2">
                    <PenViewComponent 
                      job_id={this.job_id}
                      pen_id={pen_id}
                      is_admin={this.is_admin}
                      is_mobile={this.is_mobile}
                      />
                  </Col>
                </Row>
                <Row>
                  <Col>
                    <div className="d-inline-block float-right my-2">
                      {this.is_modal == false && 
                        <React.Fragment>
                          <a className="btn btn-primary" style={{marginLeft: "10px"}} href={Routes.next_pen_path(this.state.pen_id)+"?previous"}>
                            Previous
                          </a>
                          <a className="btn btn-primary" style={{marginLeft: "10px"}} href={Routes.next_pen_path(this.state.pen_id)}>
                            Next
                          </a>
                        </React.Fragment>
                      }
                      {this.is_modal == true &&
                        <React.Fragment>
                          <button className="btn btn-primary" style={{marginLeft: "10px"}} onClick={() =>
                            postal.publish({
                              channel: "PenListComponent",
                              topic: "next_pen",
                              data: {
                                previous: true
                              }
                            })
                          }>
                            Previous
                          </button>
                          <button className="btn btn-primary" style={{marginLeft: "10px"}} onClick={ () =>
                            postal.publish({
                              channel: "PenListComponent",
                              topic: "next_pen",
                              data: {
                                previous: false
                              }
                            })
                          }>
                            Next
                          </button>
                        </React.Fragment>
                      }
                      
                    </div>
                  </Col>
                </Row>
              </React.Fragment>
            }
          </Container>
          
          
        </Card.Body>
      </Card>
    )
  }

  generateAdminView() {
    var pen = this.state.pen
    if(pen == null) {
      return null
    }

    const leftSideIssues = (pen.image_attached == false || pen.needs_reviewed == true || pen.needs_reflown == true)
    const rightSideIssues = (pen.gate_open == true || pen.cattle_in_alley == true || pen.boundary_outside == true)

    return (
      <Container fluid>
        <Row className="row-gap-3 justify-content-around">
          {pen.archived == true &&
            <Col xs="auto" className="mt-auto mb-auto">
              <table style={{marginTop: "0.5rem", marginBottom: "0.5rem"}}><tbody>
                <tr><td colSpan="100%" className="txt-lg text-nav-bg text-center text-danger">
                  <b>Pen Archived</b>
                </td></tr>
              </tbody></table>
            </Col>
          }
          <Col xs="auto" className="mt-auto mb-auto">
            <Row>
              <Col className="txt-lg text-nav-bg text-center">
                <b>Issues</b>
              </Col>
            </Row>
            <Row>
              {(leftSideIssues == false && rightSideIssues == false) &&
                <Col className="text-center">
                  <i>No Issues</i>
                </Col>  
              }
              {leftSideIssues == true && 
                <Col className="text-center">
                  {pen.image_attached == false &&
                    <div>
                      <i className="nowrap">No Image</i>
                    </div>
                  }
                  {pen.needs_reviewed == true &&
                    <div>
                      <i className="nowrap text-danger">Needs Reviewed</i>
                    </div>
                  }
                  {pen.needs_reflown == true &&
                    <div>
                      <b><i className="nowrap text-danger">Needs Reflown</i></b>
                    </div>
                  }
                </Col>
              }
              
              {rightSideIssues == true && 
                <Col className="text-center">
                  {pen.gate_open == true &&
                    <div>
                      <i className="nowrap">Gate Open</i>
                    </div>
                  }
                  {pen.cattle_in_alley == true &&
                    <div>
                      <i className="nowrap">Cattle In Alley</i>
                    </div>
                  }
                  {pen.boundary_outside == true &&
                    <div>
                      <i className="nowrap">Boundary Outside</i>
                    </div>
                  }
                </Col>
              }
            </Row>
          </Col>
          <Col xs="auto" className="mt-auto mb-auto">
            <table style={{marginTop: "0.5rem", marginBottom: "0.5rem"}}><tbody>
              <tr><td colSpan="100%" className="txt-lg text-nav-bg text-center">
                <b>Counts</b>
              </td></tr>
              <tr>
                <td className="label">
                  Yard Count: 
                </td>
                <td className="field">
                  {numberWithCommas(pen.yard_count || 0)}
                </td>
              </tr>
              <tr>
                <td className="label">
                  HC Count: 
                </td>
                <td className="field">
                  {numberWithCommas(pen.count || 0)}
                </td>
              </tr>
              <tr>
                <td className="label">
                  Difference:
                </td>
                <td className="field">
                  {numberWithCommas((pen.count || 0) - (pen.yard_count || 0))}
                </td>
              </tr>
            </tbody></table>
          </Col>
          
          <Col xs="auto" className="mt-auto mb-auto">
            <table style={{marginTop: "0.5rem", marginBottom: "0.5rem"}}><tbody>
              <tr><td colSpan="100%" className="txt-lg text-nav-bg text-center">
                <b>Misc</b>
              </td></tr>
              <tr>
                <td className="label">
                  Passes: 
                </td>
                <td className="field">
                  {pen.passes}
                  {pen.passes > 0 &&
                    <React.Fragment>
                      <i className="btn-icon fas fa-undo ml-2" 
                        data-tip data-for="reset-passes-tooltip"
                        onClick={this.resetPasses}/>
                      <ReactTooltip id='reset-passes-tooltip' effect='solid' 
                         

                        textColor="black">
                        <span>Reset Passes to Zero</span>
                      </ReactTooltip>
                    </React.Fragment>
                  }
                </td>
              </tr>
              <tr>
                <td className="label">
                  Quality Control: 
                </td>
                <td className="field">
                  <React.Fragment>
                    <button 
                      className={"btn-icon fas " + (pen.ignore ? "fa-eye-slash" : "fa-eye")} 
                      data-tip data-for={"ignore-pen-tooltip-" + pen.id}
                      onClick={()=>this.setSkipQualityControl(!pen.ignore)}/>
                    <ReactTooltip id={'ignore-pen-tooltip-'+pen.id} effect='solid' 
                       

                      textColor="black">
                      <span>{pen.ignore ? "Add To Quality Control" : "Remove From Quality Control"}</span>
                    </ReactTooltip>
                  </React.Fragment>
                </td>
              </tr>
            </tbody></table>
          </Col>
        </Row>
      </Container>
    )
  }

  generateClientView() {

    var pen = this.state.pen
    if(pen == null) {
      return null
    }

    return (
      <Container fluid>
        <Row className="row-gap-3 txt-md justify-content-around">
          <Col xs={12} md={4} lg="auto">
            <div className="w-100 text-center txt-xlg" >
              {/*<img src={this.difference_icon_path} className="pr-2" style={{height: "1.3em", verticalAlign: "middle"}}/>*/}
              <span style={{verticalAlign: "middle"}}>
                {pen.status == "complete" ? numberWithCommas((pen.count || 0) - (pen.yard_count || 0)) : "-"}
              </span>
            </div>
            <div className="w-100 text-center" style={{fontWeight: "bold"}}>
              Difference
            </div>
          </Col>
          <Col xs={6} md={4} lg="auto">
            <div className="w-100 text-center txt-xlg" >
              {/*<img src={this.tally_icon_path} className="pr-2" style={{height: "1.3em", verticalAlign: "middle"}}/>*/}
              <span style={{verticalAlign: "middle"}}>
                {numberWithCommas(pen.yard_count || 0)}
              </span>
            </div>
            <div className="w-100 text-center" style={{fontWeight: "bold"}}>
              Yard Count
            </div>
          </Col>
          <Col xs={6} md={4} lg="auto">
            <div className="w-100 text-center txt-xlg" >
              {/*<img src={this.hc_icon_path} className="pr-2" style={{height: "1.3em", verticalAlign: "middle"}}/>*/}
              <span style={{verticalAlign: "middle"}}>
                {pen.status == "complete" ? numberWithCommas(pen.count || 0) : "-"}
              </span>
            </div>
            <div className="w-100 text-center" style={{fontWeight: "bold"}}>
              HC Count
            </div>
          </Col>
          <Col xs={6} md={4} lg="auto">
            <div className="w-100 text-center txt-xlg" >
              <span style={{verticalAlign: "middle"}}>
                {pen.status == "complete" ? booleanToYesNo(pen.gate_open) : "-"}
              </span>
            </div>
            <div className="w-100 text-center" style={{fontWeight: "bold"}}>
              Gate Open
            </div>
          </Col>
          <Col xs={6} md={4} lg="auto">
            <div className="w-100 text-center txt-xlg" >
              <span style={{verticalAlign: "middle"}}>
                {pen.status == "complete" ? booleanToYesNo(pen.cattle_in_alley) : "-"}
                </span>
              </div>
            <div className="w-100 text-center" style={{fontWeight: "bold"}}>
              Cattle in Alley
            </div>
          </Col>
          {pen.needs_reflown == true && 
            <Col xs={6} md={4} lg="auto" className="mt-auto mb-auto">
              <div className="w-100 text-center txt-lg text-danger">
                <span style={{verticalAlign: "middle"}} className={pen.status == "complete" && pen.cattle_in_alley ? "text-warning" : ""}>
                  Needs Reflown
                </span>
              </div>
            </Col>
          }
        </Row>
      </Container>
    )
  }

  generateClientViewCondensed() {

    var pen = this.state.pen
    if(pen == null) {
      return null
    }

    const issues = (pen.needs_reflown == true || pen.gate_open == true || pen.cattle_in_alley == true)

    return (
      <Container fluid>
        <Row className="row-gap-3 justify-content-around">
          <Col xs="auto">
            <table><tbody>
              <tr><td colSpan="100%" className="txt-lg text-nav-bg text-center">
                <b>Counts</b>
              </td></tr>
              <tr>
                <td className="label">
                  Yard Count: 
                </td>
                <td className="field">
                  {numberWithCommas(pen.yard_count || 0)}
                </td>
              </tr>
              <tr>
                <td className="label">
                  HC Count: 
                </td>
                <td className="field">
                  {numberWithCommas(pen.count || 0)}
                </td>
              </tr>
              <tr>
                <td className="label">
                  Difference:
                </td>
                <td className="field">
                  {numberWithCommas((pen.count || 0) - (pen.yard_count || 0))}
                </td>
              </tr>
            </tbody></table>
          </Col>
          <Col xs="auto">
            <Row>
              <Col className="txt-lg text-nav-bg text-center">
                <b>Issues</b>
              </Col>
            </Row>
            <Row>
              {(issues == false) &&
                <Col className="text-center">
                  <i>No Issues</i>
                </Col>  
              }
              {issues == true && 
                <Col className="text-center">
                  {pen.needs_reflown == true &&
                    <div>
                      <b><i className="nowrap text-danger">Needs Reflown</i></b>
                    </div>
                  }
                  {pen.gate_open == true &&
                    <div>
                      <i className="nowrap">Gate Open</i>
                    </div>
                  }
                  {pen.cattle_in_alley == true &&
                    <div>
                      <i className="nowrap">Cattle In Alley</i>
                    </div>
                  }
                </Col>
              }
            </Row>
          </Col>
        </Row>
      </Container>
    )
  }

  subscribe() {
    if (this.state.pen_id != null) {
      var _this = this
      return App.cable.subscriptions.create(
        {
          channel: "PenDashboardChannels::PenDescriptionCardChannel",
          feedlot_id: this.feedlot_id,
          job_id: this.job_id,
          pen_id: this.state.pen_id,
          is_admin: this.is_admin
        },
        {  
          connected() {
            _this.getPen()
          },

          received(data) {
            if (data.pen != null) {
              _this.setState({
                pen: data.pen
              })
            } else if(data.message == "pen_updated") {
              _this.getPen()
            } else if (this.is_admin == true) {
              if(data.heart_beats != null) {
                _this.setState({
                  heart_beats: data.heart_beats
                })
              } else if(data.heart_beat != null) {
                _this.updateHeartBeat(data.heart_beat)
              } else if(data.message == "heart_beat_updated") {
                _this.getHeartBeat(data.heart_beat_id)
              } else if(data.message == "heart_beat_removed") {
                _this.removeHeartBeat(data.heart_beat_id)
              }
            } 
          }
        }
      )
    }
    return null
  }

  getPen() {
    this.listener.perform("get_pen")
  }

  getHeartBeats() {
    this.listener.perform("get_heart_beats")
  }

  getHeartBeat(heart_beat_id) {
    this.listener.perform("get_heart_beat", {id: heart_beat_id})
  }

  removeHeartBeat(heart_beat_id) {

  }

  updateHeartBeat(heart_beat) {

  }

  uploadImage() {
    postal.publish({
      channel: "EditPenModal",
      topic: "open",
      data: {
        pen_id: this.state.pen_id,
        pen_name: this.state.pen.name,
        type: "upload_image"
      }
    })
  }

  editName() {
    postal.publish({
      channel: "EditPenModal",
      topic: "open",
      data: {
        pen_id: this.state.pen_id,
        pen_name: this.state.pen.name,
        type: "pen_name"
      }
    })
  }

  editYardCount() {
    postal.publish({
      channel: "EditPenModal",
      topic: "open",
      data: {
        pen_id: this.state.pen_id,
        pen_name: this.state.pen.name,
        type: "yard_count"
      }
    })
  }

  duplicatePen() {
    postal.publish({
      channel: "DuplicatePenModal",
      topic: "open",
      data: {
        pen_id: this.state.pen_id,
        pen_name: this.state.pen.name
      }
    })
  }

  setSkipQualityControl(bool_value) {
    var jform = new FormData();
    jform.append('utf8',"&#x2713;");
    jform.append('authenticity_token',form_authenticity_token());
    jform.append('value', bool_value);
    
    $.ajax({
      url: Routes.edit_skip_quality_control_admin_pen_path(this.state.pen_id),
      type: "POST",
      data: jform,
      dataType: 'json',
      mimeType: 'multipart/form-data',
      contentType: false,
      cache: false,
      processData: false,
      success: function(response) {
        if (response.status == "Success") {
          postAlert("success", bool_value ? "Removed From Quality Control" : "Added To Quality Control")
        } else {
          postAlert("danger", `Failed To Set Skip Quality Control`)
        }
      },
      error: function(request, textStatus, errorThrown) {
        postAlert("danger", `Failed To Set Skip Quality Control`)
      },
      complete: function() {}
    })
  }

  // true sets it to complete
  // false sets it to new
  setStatus(bool_value) {
    var jform = new FormData();
    jform.append('utf8',"&#x2713;");
    jform.append('authenticity_token',form_authenticity_token());
    jform.append('value', bool_value);
    
    $.ajax({
      url: Routes.edit_status_admin_pen_path(this.state.pen_id),
      type: "POST",
      data: jform,
      dataType: 'json',
      mimeType: 'multipart/form-data',
      contentType: false,
      cache: false,
      processData: false,
      success: function(response) {
        if (response.status == "Success") {
          postAlert("success", `Successfully Set Status to ${bool_value ? "Complete" : "New"}`)
        } else {
          postAlert("danger", `Failed To Set Status`)
        }
      },
      error: function(request, textStatus, errorThrown) {
        postAlert("danger", `Failed To Set Status`)
      },
      complete: function() {}
    })
  }

  setArchive() {
    if (ask("Are You Sure You Want To Archive This Pen?")) {
      var jform = new FormData();
      jform.append('utf8',"&#x2713;");
      jform.append('authenticity_token',form_authenticity_token());
      
      $.ajax({
        url: Routes.archive_admin_pen_path(this.state.pen_id),
        type: "POST",
        data: jform,
        dataType: 'json',
        mimeType: 'multipart/form-data',
        contentType: false,
        cache: false,
        processData: false,
        success: function(response) {
          if (response.status == "Success") {
            postAlert("success", `Successfully Archived Pen`)
          } else {
            postAlert("danger", `Failed To Archive Pen`)
          }
        },
        error: function(request, textStatus, errorThrown) {
          postAlert("danger", `Failed To Archive Pen`)
        },
        complete: function() {}
      })
    }
  }

  resetPasses() {
    if (ask("Are You Sure You Want To Archive This Pen?")) {
      var jform = new FormData();
      jform.append('utf8',"&#x2713;");
      jform.append('authenticity_token',form_authenticity_token());
      
      $.ajax({
        url: Routes.reset_passes_admin_pen_path(this.state.pen_id),
        type: "POST",
        data: jform,
        dataType: 'json',
        mimeType: 'multipart/form-data',
        contentType: false,
        cache: false,
        processData: false,
        success: function(response) {
          if (response.status == "Success") {
            postAlert("success", `Successfully Reset Passes`)
          } else {
            postAlert("danger", `Failed To Reset Passes`)
          }
        },
        error: function(request, textStatus, errorThrown) {
          postAlert("danger", `Failed To Reset Passes`)
        },
        complete: function() {}
      })
    }
  }
}